import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { home_tiles_helper } from "@/helpers/home_tiles_helper";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import { testimonials_helper } from "@/helpers/testimonials_helper";
import { blog_helper } from "@/helpers/blog_helper";
import Head from "next/head";
import { HomeHero } from "@/components";
// import { homepage_quote_helper } from "@/helpers/homepage_quote_helper";
// import { homepage_tiles_helper } from "@/helpers/homepage_tiles_helper";
// import { jobs_helper } from "@/helpers/jobs_helper";

export default function Page({ content }) {
  return (
    <>
      <Head>
      <meta name="google-site-verification" content="EcMFWng41sorcZJkcjXKbfIWb_SJ_VPv-dSHvlEe2wM" />
        <meta
          name="image"
          property="og:image"
          content="https://cdn.sourceflow.co.uk/6it2sjggx11a43ri0o5z25yod4uo"
        />
        <meta
          name="twitter:image"
          content="https://cdn.sourceflow.co.uk/6it2sjggx11a43ri0o5z25yod4uo"
        />
      </Head>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "HomeHero",
          skipFade: true,
          props: {
            title: {
              path: "page.home.component.Hero.title",
              placeholder: "Title",
            },
            title2: {
              path: "page.home.component.Hero.title2",
              placeholder: "Title",
            },
            boldTitle: {
              path: "page.home.component.Hero.boldTitle",
              placeholder: "Subtitle",
            },
            description: {
              path: "page.home.component.Hero.description",
              placeholder: "Personalised relationships, Sector specialisms, Global presence",
            },
            video: "https://cdn.sourceflow.co.uk/2f7ps1560svl4aw64sshvjh1dq5a",
            className: "text-white"
          },
        },
        {
          component: "HomeTiles",
          props: {
            items: home_tiles_helper.fetch(),
          }
        },
        {
          component: "Specialisms",
          props: {
            items: specialisms_helper.fetch({onlyParent: true}),
          }
        },
        {
          component: "Testimonials",
          props: {
            items: testimonials_helper.fetch(),
          }
        },
        {
          component: "LatestBlogs",
          props: {
            title: "LATEST NEWS & INSIGHTS",
            newsTitle: { path: "page.home.latest-news.Header.title", placeholder: "WHAT'S NEW ON" },
          },
        },
      ],
    },
  };
}
