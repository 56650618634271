import { lazy } from "react";

export const BreadcrumbNavigation = lazy(() => import("./BreadcrumbNavigation"));
export const HeroSimple = lazy(() => import("./HeroSimple"));
export const Header = lazy(() => import("./Header"));
export const FeefoReviews = lazy(() => import("./FeefoReviews"));
export const NoSSR = lazy(() => import("./NoSSR"));
export const Services = lazy(() => import("./Services"));
export const EmployeeFeedbacks = lazy(() => import("./EmployeeFeedbacks"));
export const Hero2 = lazy(() => import("./Hero2"));
export const HomeHero = lazy(() => import("./HomeHero"));
export const Testimonials = lazy(() => import("./Testimonials"));
export const HomeJobSearch = lazy(() => import("./HomeJobSearch"));
export const HomeTiles = lazy(() => import("./HomeTiles"));
export const Specialisms = lazy(() => import("./Specialisms"));
export const SpecialismCategories = lazy(() => import("./SpecialismCategories"));
export const OurBrands = lazy(() => import("./OurBrands"));
export const OurValues = lazy(() => import("./OurValues"));
export const StatsHighlight = lazy(() => import("./StatsHighlight"));
export const CEOStatement = lazy(() => import("./CEOStatement"));
export const CharityStatement = lazy(() => import("./CharityStatement"));
export const LatestBlogs = lazy(() => import("./LatestBlogs"));
export const LatestJobs = lazy(() => import("./LatestJobs"));
export const Benefits = lazy(() => import("./Benefits"));
export const Relocations = lazy(() => import("./Relocations"));
export const MeetTheTeamPanel = lazy(() => import("./MeetTheTeamPanel"));
export const MeetTheLeaders = lazy(() => import("./MeetTheLeaders"));
export const EthyWidget = lazy(() => import("./EthyWidget"));
export const SeoJobsFeed = lazy(() => import("./SeoJobsFeed"));
export const Cookies = lazy(() => import("./Cookies"));
export const Carousel = lazy(() => import("./Carousel"));
export const NowSignage = lazy(() => import("./NowSignage"));
export const TeamTiles = lazy(() => import("./TeamTiles"));
